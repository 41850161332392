import React, { FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

import {
  FwModuleProvider,
  FwPageProvider,
  FwTemplatesProvider,
  useFwAuth,
} from 'components/base';
import { getToken } from 'core/utils/storage';

const PrivateComponent: FC<RouteProps> = ({ path, children }) => {
  const token = getToken();
  const { user, checkUserAccess } = useFwAuth();

  if (user && token) {
    checkUserAccess();
  }

  return user && token ? (
    <FwTemplatesProvider>
      <FwModuleProvider>
        <FwPageProvider>{children}</FwPageProvider>
      </FwModuleProvider>
    </FwTemplatesProvider>
  ) : (
    <Navigate replace to={`${path}public/login`} />
  );
};

export default PrivateComponent;
