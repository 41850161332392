import _ from 'lodash';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AdminComponent } from 'components';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import routesAdmin from './routesAdmin';

const renderPageSegment = () => (
  <TilesPanel routes={_.filter(routesAdmin, { type: 'admin' })} />
);

const Admin = () => {
  return (
    <Routes>
      <Route path={`/`} element={renderPageSegment()} />
      {_.map(
        routesAdmin,
        ({ exact, path, relativePath, component: Component }, i) => {
          return (
            <Route
              key={path}
              path={`${relativePath}${!exact ? '/*' : ''}`}
              element={<AdminComponent key={i} render={() => <Component />} />}
            />
          );
        }
      )}
    </Routes>
  );
};

export default Admin;
