import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';

import authenticationApi from 'api/authentication/authenticationApi';
import { EntityInfo, Table } from 'components';
import { useFwSettings } from 'components/base';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import {
  getAdminInputs,
  Identity,
  identity0Rules,
  identity1Rules,
} from 'core/model';
import Config2FA from 'scenes/dashboard/scenes/account/components/page/Config2FA';

const User: FC<Props> = ({
  // props
  identity,
  tableStruct,
  // functions
  handleAddRow,
  handleDeleteRow,
  handleSetUsername,
  t,
}) => {
  const { settings } = useFwSettings();

  // get entityDoc for update user and password
  const castI: Identity = Object.assign(new Identity(), identity);

  // get entityDoc for update user and password
  const inputs0 = getAdminInputs(castI, null, t, '0');
  const entityDoc0 = getEntityDoc(identity, inputs0, identity0Rules);

  // get entityDoc for update role
  const inputs1 = getAdminInputs(castI, null, t, '1');
  const entityDoc1 = getEntityDoc(identity, inputs1, identity1Rules);

  return (
    <>
      {identity && (
        <EntityInfo
          api={authenticationApi}
          entityDoc={entityDoc0}
          keyName={'identityID'}
          entityKey={identity.username}
          type={'identity'}
          title={'Identity'}
          onSetKey={handleSetUsername}
        />
      )}
      {identity.username && (
        <>
          <EntityInfo
            autosave
            api={authenticationApi}
            entityDoc={entityDoc1}
            keyName={'identityID'}
            entityKey={identity.username}
            type={'role'}
            title={'Role'}
          />
          {settings?.allow2FA && (
            <Config2FA
              username={castI.username}
              twoFactorEnabled={castI.twoFactorEnabled}
              authenticatorEnabled={castI.authenticatorEnabled}
            />
          )}
        </>
      )}
      {identity && identity.id && (
        <Table
          allowAddRow
          allowDeleteRow
          allowDoubleClick
          table={tableStruct}
          module={identity}
          dataType="Field_User"
          dataKey="FieldID"
          handleAddRow={handleAddRow}
          handleDeleteRow={handleDeleteRow}
        />
      )}
    </>
  );
};

const propTypes = {
  t: func.isRequired,
  tableStruct: any,
  identity: any.isRequired,
  handleAddRow: func.isRequired,
  handleDeleteRow: func.isRequired,
  handleSetUsername: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

User.propTypes = propTypes;

export default withTranslation()(User);
