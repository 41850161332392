import { Route } from 'core/model';

import Area from './scenes/area/areaContainer';
import AreaLayout from './scenes/area/layout/areaLayoutContainer';
import AdminFiles from './scenes/files/AdminFiles';
import AdminFilesUpload from './scenes/files/AdminFiles.Upload';
import AdminGrids from './scenes/grids/adminGridsContainer';
import Language from './scenes/language/languageContainer';
import LanguageLayout from './scenes/language/layout/languageLayoutContainer';
import Modules from './scenes/modules/modulesContainer';
import Module from './scenes/modules/scenes/module/moduleContainer';
import Pages from './scenes/pages/pagesContainer';
import PageEdit from './scenes/pages/scenes/edit/pageEditContainer';
import PageContent from './scenes/pages/scenes/edit/scenes/pageContent/pageContentContainer';
// grids./scenes/grids/adminGridsContainer
import Input from './scenes/pages/scenes/edit/scenes/pageContent/scenes/input/inputContainer';
import Settings from './scenes/settings/settingsContainer';
// import AdminGrid from './scenes/grids/scenes/grid/adminGridContainer';
// // templates
// import TemplatesWithId from './scenes/templates/templatesWithIdContainer';
// import TemplatesWithKey from './scenes/templates/templatesWithKeyContainer';
// import TemplateLayout from './scenes/templates/layout/templateLayoutContainer';
import Template from './scenes/templates/scenes/template/templateContainer';
import Templates from './scenes/templates/templatesContainer';
// users
import Users from './scenes/users/adminUsersContainer';
import UserFilter from './scenes/users/scenes/user/scenes/userFilter/userFilterContainer';
import User from './scenes/users/scenes/user/userContainer';
//import RegisterAdmin from './scenes/register-admin/RegisterAdmin';
//import Autofield from './scenes/field-action/autofieldContainer';

export enum ROUTE_TYPES {
  adminFiles = 'admin/files',
}

export enum ROUTE_NAMES {
  files = 'Files',
}

// type 'admin' (accessed from admin tiles)
const routesAdmin: Route[] = [
  {
    path: '/admin/settings',
    relativePath: 'settings',
    exact: true,
    type: 'admin',
    component: Settings,
    name: 'Settings',
    design: { icon: 'RiEqualizerFill' },
  },
  {
    path: '/admin/users',
    relativePath: 'users',
    exact: true,
    type: 'admin',
    component: Users,
    name: 'Users',
    design: { icon: 'RiTeamFill' },
  },
  {
    path: '/admin/users/:name?',
    relativePath: 'users/:name?',
    exact: true,
    type: 'admin/users',
    component: User,
    name: 'User',
  },
  {
    path: '/admin/users/register',
    relativePath: 'users/register',
    exact: true,
    type: 'admin/users',
    component: User,
    name: 'Register',
  },
  {
    path: '/admin/users/:name?/newFilterUser',
    relativePath: 'users/:name?/newFilterUser',
    exact: true,
    type: 'admin/users/filter',
    component: UserFilter,
    name: 'UserFilter',
  },
  {
    path: '/admin/users/:name?/:fieldId?',
    relativePath: 'users/:name?/:fieldId?',
    exact: true,
    type: 'admin/users/filter',
    component: UserFilter,
    name: 'UserFilter',
  },
  //{
  //  path: '/admin/users/edit',
  //  relativePath: 'users/edit',
  //  exact: true,
  //  type: 'admin/users',
  //  component: RegisterAdmin,
  //  name: 'Edit',
  //  icon: 'user plus',
  //},
  {
    path: '/admin/area',
    relativePath: 'area',
    exact: true,
    type: 'admin',
    component: Area,
    name: 'Areas',
    design: { icon: 'RiPictureInPictureFill' },
  },
  {
    path: '/admin/area/layout/:name?',
    relativePath: 'area/layout/:name?',
    exact: true,
    type: 'admin/area',
    component: AreaLayout,
    name: 'Area',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/language',
    relativePath: 'language',
    exact: true,
    type: 'admin',
    component: Language,
    name: 'Languages',
    design: { icon: 'RiAB' },
  },
  {
    path: '/admin/language/layout/:name?',
    relativePath: 'language/layout/:name?',
    exact: true,
    type: 'admin/language',
    component: LanguageLayout,
    name: 'Language',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/modules',
    relativePath: 'modules',
    exact: true,
    type: 'admin',
    component: Modules,
    name: 'Modules',
    design: { icon: 'RiLayoutLeft2Fill' },
  },
  {
    path: '/admin/modules/edit/:key?',
    relativePath: 'modules/edit/:key?',
    exact: true,
    type: 'admin/modules',
    component: Module,
    name: 'Module',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/page',
    relativePath: 'page',
    exact: true,
    type: 'admin',
    component: Pages,
    name: 'Pages',
    design: { icon: 'RiSideBarFill' },
  },
  {
    path: '/admin/page/edit/:moduleKey/:pageKey?',
    relativePath: 'page/edit/:moduleKey/:pageKey?',
    exact: true,
    type: 'admin/page',
    component: PageEdit,
    name: 'Page',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/page/edit/:moduleKey/:pageKey?/newPageContent',
    relativePath: 'page/edit/:moduleKey/:pageKey?/newPageContent',
    exact: true,
    type: 'admin/page/pageContent',
    component: PageContent,
    name: 'PageContent',
  },
  {
    path: '/admin/page/edit/:moduleKey/:pageKey?/:pageContentId?',
    relativePath: 'page/edit/:moduleKey/:pageKey?/:pageContentId?',
    exact: true,
    type: 'admin/page/pageContent',
    component: PageContent,
    name: 'PageContent',
  },
  {
    path: '/admin/grids',
    relativePath: 'grids',
    exact: true,
    type: 'admin',
    component: AdminGrids,
    name: 'Grids',
    design: { icon: 'RiGridFill' },
  },
  //{
  //  path: '/admin/grids/:key?',
  //  relativePath: 'grids/:key?',
  //  exact: true,
  //  type: 'admin/grid',
  //  component: AdminGrid,
  //  name: 'Grid',
  //  design: { icon: 'RiAddLine' },
  //},
  {
    path: '/admin/templates',
    relativePath: 'templates',
    exact: true,
    type: 'admin',
    component: Templates,
    name: 'Templates',
    design: { icon: 'RiFileCopyLine' },
  },
  {
    path: '/admin/template/edit/:templateKey?',
    relativePath: 'template/edit/:templateKey?',
    exact: true,
    type: 'admin/template',
    component: Template,
    name: 'Template layout',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/template/edit/:templateKey?/:pageKey?',
    relativePath: 'template/edit/:templateKey?/:pageKey?',
    exact: true,
    type: 'admin/template/step',
    component: PageEdit,
    name: 'Step',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/template/edit/:templateKey?/:pageKey?/newPageContent',
    relativePath: 'template/edit/:templateKey?/:pageKey?/newPageContent',
    exact: true,
    type: 'admin/template/step/content',
    component: PageContent,
    name: 'PageContent',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/template/edit/:templateKey?/:pageKey?/:pageContentId?',
    relativePath: 'template/edit/:templateKey?/:pageKey?/:pageContentId?',
    exact: true,
    type: 'admin/template/step/content',
    component: PageContent,
    name: 'PageContent',
    design: { icon: 'RiAddLine' },
  },
  {
    path: '/admin/template/edit/:templateKey/:pageKey?/:pageContentId?/newInputId',
    relativePath:
      'template/edit/:templateKey/:pageKey?/:pageContentId?/newInputId',
    exact: true,
    type: 'admin/page/pageContent/input',
    component: Input,
    name: 'Input',
  },
  {
    path: '/admin/template/edit/:templateKey/:pageKey?/:pageContentId?/:inputId?',
    relativePath:
      'template/edit/:templateKey/:pageKey?/:pageContentId?/:inputId?',
    exact: true,
    type: 'admin/page/pageContent/input',
    component: Input,
    name: 'Input',
  },
  {
    path: '/admin/files',
    relativePath: 'files',
    exact: true,
    type: 'admin',
    component: AdminFiles,
    name: ROUTE_NAMES.files,
    design: { icon: 'RiFileEditLine' },
  },
  {
    path: '/admin/files/upload',
    relativePath: 'files/upload',
    exact: true,
    type: ROUTE_TYPES.adminFiles,
    component: AdminFilesUpload,
    name: 'File',
    design: { icon: 'RiAddLine' },
  },
  //{
  //  path: '/admin/templates',
  //  relativePath: 'templates',
  //  exact: true,
  //  type: 'admin',
  //  component: TemplatesWithKey,
  //  name: 'Templates',
  //  design: { icon: 'RiFileCopyLine' },
  //},
  //{
  //  path: '/admin/templates/layout/:key',
  //  relativePath: 'templates/layout/:key',
  //  exact: true,
  //  type: 'admin/template',
  //  component: TemplateLayout,
  //  name: 'Template layout',
  //  design: { icon: 'RiAddLine' },
  //},
  //{
  //  path: '/admin/templates/new/layout/',
  //  relativePath: 'templates/new/layout',
  //  exact: true,
  //  type: 'admin/template',
  //  component: TemplateLayout,
  //  name: 'Template layout',
  //  design: { icon: 'RiAddLine' },
  //},
  //{
  //  path: '/admin/autofill',
  //  relativePath: 'autofill',
  //  exact: true,
  //  type: 'admin',
  //  component: TemplatesWithId,
  //  name: 'Autofill',
  //  design: { icon: 'RiPencilFill' },
  //},
  //{
  //  path: '/admin/autofill/layout/:id(\\d+)?',
  //  relativePath: 'autofill/layout/:id(\\d+)?',
  //  exact: true,
  //  type: 'admin/autofill',
  //  component: Autofield,
  //  name: 'Autofill',
  //  icon: 'pencil alternate',
  //},
  //{
  //  path: '/admin/autohide',
  //  relativePath: 'autohide',
  //  exact: true,
  //  type: 'admin',
  //  component: TemplatesWithId,
  //  name: 'Autohide',
  //  design: { icon: 'RiEraserFill' },
  //},
  //{
  //  path: '/admin/autohide/layout/:id(\\d+)?',
  //  relativePath: 'autohide/layout/:id(\\d+)?',
  //  exact: true,
  //  type: 'admin/autohide',
  //  component: Autofield,
  //  name: 'Autofill',
  //  icon: 'eraser',
  //},
];

export default routesAdmin;
