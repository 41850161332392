import _ from 'lodash';

import { areaIsMain } from 'components/base';
import { AppUser, Area, Route } from 'core/model';
import Utils from 'core/utils/utils';
import routes from 'scenes/dashboard/routes';

const unreleasedRoutes = ['/dev'];

const getFilteredRoutes = (
  currentUser: AppUser,
  area: Area,
  isProd: boolean
) => {
  const userIsAdmin = Utils.canAccess(currentUser, 'admin');
  const isAdminDebug = userIsAdmin && !isProd && areaIsMain(area);
  const noUnreleasedMain = !unreleasedRoutes && areaIsMain(area);

  // filter
  const filteredRoutes =
    routes && currentUser
      ? routes
          .filter((r: Route) => {
            if (isAdminDebug || noUnreleasedMain) {
              // allow all routes
              return true;
            }

            // else
            return (
              // if prod or in area (not main area) or non-admin user...
              (isProd || !areaIsMain(area) || !userIsAdmin) &&
              // ...hide unreleased routes
              !_.some(unreleasedRoutes, (ur) => ur === r.path) &&
              // ...and then filter by area
              (areaIsMain(area) || r.areable)
            );
          })
          .filter((route: Route) => {
            if (route.path === '/admin') {
              return userIsAdmin && areaIsMain(area);
            } else if (route.path === '/reporting') {
              return Utils.canAccess(currentUser, 'reporting');
            } else if (route.path === '/analytics') {
              return Utils.canAccess(currentUser, 'analytics');
            }

            return true;
          })
      : undefined;

  // apply area routing
  return filteredRoutes;
};

export default getFilteredRoutes;
