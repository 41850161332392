import _ from 'lodash';
import { InferProps, instanceOf, node } from 'prop-types';
import React, { FC, useEffect, useRef, useState } from 'react';

import { useApi } from 'api';
import moduleApi from 'api/module/moduleApi';
import {
  FwSpinner,
  useFwArea,
  useFwAuth,
  useFwSettings,
} from 'components/base';
import { Module, Route } from 'core/model';

import { FwModuleContext } from './FwModuleContext';
import { filterModules } from './helper';

const FwModuleProvider: FC<Props> = ({ children, module: moduleProp }) => {
  const { area } = useFwArea();
  const { user } = useFwAuth();
  const {
    settings: { isProd },
  } = useFwSettings();

  const isProdRef = useRef(isProd);
  const argsRef = useRef([]);
  const moduleRoutesRef = useRef<Route[]>();
  const [module, setModule] = useState<Module>();
  const [defaultModule, setDefaultModule] = useState<Module>();
  const [moduleRoutes, setModuleRoutes] = useState<Route[]>();
  const [allModuleRoutes, setAllModuleRoutes] = useState<Route[]>();

  const { fetched: fetchedModules, pending: pendingModulesFetch } = useApi(
    !moduleProp ? moduleApi.getAll : null,
    argsRef.current
  );

  useEffect(() => {
    setModule(moduleProp);
  }, [moduleProp]);

  useEffect(() => {
    // todo wip#585 fix condition
    if (
      (!pendingModulesFetch &&
        !_.isEmpty(fetchedModules?.modules) &&
        !moduleRoutesRef.current) ||
      isProdRef.current !== isProd
    ) {
      const { activeModule, moduleRoutes, allModuleRoutes } = filterModules(
        fetchedModules.modules,
        isProd,
        area,
        user
      );

      setDefaultModule(activeModule);
      setModuleRoutes(moduleRoutes);
      setAllModuleRoutes(allModuleRoutes);
      isProdRef.current = isProd;
      moduleRoutesRef.current = moduleRoutes;
    }
  }, [area, fetchedModules, isProd, pendingModulesFetch, user]);

  const activeModule = module || defaultModule;

  return !moduleProp &&
    (pendingModulesFetch ||
      !activeModule ||
      _.isEmpty(moduleRoutes) ||
      _.isEmpty(allModuleRoutes)) ? (
    <FwSpinner />
  ) : (
    <FwModuleContext.Provider
      value={{ module: activeModule, moduleRoutes, allModuleRoutes, setModule }}
    >
      {children}
    </FwModuleContext.Provider>
  );
};

const propTypes = { children: node, module: instanceOf(Module) };

type Props = InferProps<typeof propTypes>;

FwModuleProvider.propTypes = propTypes;

export { FwModuleProvider };
