import {
  Container,
  HStack,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwSegment } from 'components/base';
import { IMAGE_URL } from 'core/utils/constant';

const { _dark, _light } = IMAGE_URL.footer;

const FwFooter: FC<Props> = () => {
  const { t } = useTranslation();
  const logoUrl = useColorModeValue(_light, _dark);

  const text = 'Powered by';

  const textStyle = {
    fontSize: 'xs',
  };

  return (
    <FwSegment as={'footer'} basic frosted>
      <Container
        as={VStack}
        spacing="2px"
        sx={{ '@media print': { display: 'none' } }}
      >
        <HStack mt="-8px" mb="-8px" spacing={0}>
          <Text {...textStyle}>{t(`glossary|${text}`)}</Text>
          <a href="https://fasteroptim.com">
            <Image src={logoUrl} height="35px" />
          </a>
        </HStack>
        <Text {...textStyle}>
          &copy; {new Date().getFullYear()} (v0.7.0-pre01) - Fastercom
        </Text>
      </Container>
    </FwSegment>
  );
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwFooter.propTypes = propTypes;

export default FwFooter;
